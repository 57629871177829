// /src/components/Marketing/MarketingCampaignModal.js

import React, { useState, useEffect } from "react";
import axios from "axios";

function MarketingCampaignModal({ onClose, userPhoneID }) {
  const [templateId, setTemplateId] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [numbers, setNumbers] = useState("");
  const [csvFile, setCsvFile] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [remainingQuota, setRemainingQuota] = useState(100);
  const [viewingPreviousCampaigns, setViewingPreviousCampaigns] =
    useState(false);
  const [formattedNumbers, setFormattedNumbers] = useState([]);
  const token = localStorage.getItem("jwtToken");

  const templatesUrl = process.env.REACT_APP_GET_TEMPLATES;
  const getCampaignsUrl = process.env.REACT_APP_GET_CAMPAIGNS;
  const sendMarketingCampaignUrl = process.env.REACT_APP_SEND_MARKETING;

  // Función para obtener los templates desde la API de WhatsApp
  const fetchTemplates = async () => {
    try {
      const response = await axios.get(templatesUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTemplates(response.data.templates);
    } catch (error) {
      console.error("Error fetching templates:", error);
      alert("Error al obtener los templates de WhatsApp");
    }
  };

  // Función para obtener las campañas del usuario y la cuota restante
  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(getCampaignsUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userPhoneID,
        },
      });
      setCampaigns(response.data.campaigns);
      setRemainingQuota(response.data.remainingQuota);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      alert("Error al obtener las campañas");
    }
  };

  // Llamar a fetchTemplates y fetchCampaigns al montar el componente
  useEffect(() => {
    fetchTemplates();
    fetchCampaigns();
  }, []);

  /**
   * Función para validar y formatear números de teléfono.
   * Evita la duplicación del prefijo '+34'.
   *
   * @param {string[]} numberList - Lista de números de teléfono.
   * @returns {string[]} - Lista de números formateados y válidos.
   */
  const validateAndFormatNumbers = (numberList) => {
    const DEFAULT_PREFIX = "+34"; // Cambia esto según el prefijo que necesites
    const regex = /^\+\d{10,15}$/; // Define el formato esperado
    const formattedNumbers = [];
    const invalidNumbers = [];

    numberList.forEach((num) => {
      // Eliminar espacios y caracteres no deseados
      let cleanedNumber = num.replace(/\s+/g, "");

      // Verificar si el número ya tiene un prefijo '+'
      if (cleanedNumber.startsWith("+")) {
        // Si ya comienza con '+', asumimos que está correctamente formateado
        // Puedes añadir validaciones adicionales si lo deseas
      } else if (
        cleanedNumber.startsWith("34") &&
        cleanedNumber.length === 11
      ) {
        // Si comienza con '34' y tiene 11 dígitos, añadimos '+'
        cleanedNumber = `+${cleanedNumber}`;
      } else {
        // Añadir el prefijo predeterminado '+34'
        cleanedNumber = `${DEFAULT_PREFIX}${cleanedNumber}`;
      }

      // Validar el formato usando una expresión regular
      if (regex.test(cleanedNumber)) {
        formattedNumbers.push(cleanedNumber);
      } else {
        console.warn(`Número inválido omitido: ${cleanedNumber}`);
        invalidNumbers.push(cleanedNumber);
      }
    });

    if (invalidNumbers.length > 0) {
      alert(`Se omitieron ${invalidNumbers.length} números inválidos.`);
    }

    return formattedNumbers;
  };

  // Función para manejar el envío de la campaña
  const handleSendCampaign = async () => {
    if (!campaignName.trim()) {
      alert("Por favor, ingresa un nombre para la campaña.");
      return;
    }

    if (!templateId) {
      alert("Por favor, selecciona un template aprobado.");
      return;
    }

    // Procesar números
    let numberList = numbers
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num !== "");
    if (csvFile) {
      try {
        const csvData = await csvFile.text();
        const csvLines = csvData.split("\n");
        const csvNumbers = csvLines
          .map((line) => line.split(",")[0].trim())
          .filter((num) => num !== "");
        numberList = numberList.concat(csvNumbers);
      } catch (error) {
        console.error("Error procesando el archivo CSV:", error);
        alert("Error al procesar el archivo CSV.");
        return;
      }
    }

    // Eliminar posibles números duplicados
    numberList = [...new Set(numberList)];

    if (numberList.length === 0) {
      alert("Por favor, ingresa al menos un número de teléfono.");
      return;
    }

    // Validar y formatear los números
    const formattedNumberList = validateAndFormatNumbers(numberList);
    setFormattedNumbers(formattedNumberList);

    if (formattedNumberList.length > remainingQuota) {
      alert(
        `Has excedido el límite diario de mensajes. Te quedan ${remainingQuota} mensajes.`
      );
      return;
    }

    const payload = {
      userPhoneID,
      templateId,
      campaignName: campaignName.trim(),
      numbers: formattedNumberList,
    };

    try {
      const response = await axios.post(sendMarketingCampaignUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert("Campaña enviada correctamente");
      onClose(); // Cerrar el modal después de enviar la campaña
      fetchCampaigns(); // Actualizar la lista de campañas y la cuota restante
    } catch (error) {
      console.error("Error al enviar campaña:", error);
      alert("Error al enviar la campaña");
    }
  };

  // Función para manejar la selección de una campaña para ver detalles
  const handleSelectCampaign = (campaign) => {
    setSelectedCampaign(campaign);
  };

  // Función para cerrar la vista de detalles de una campaña
  const handleCloseDetails = () => {
    setSelectedCampaign(null);
  };

  // Función para alternar entre vistas y limpiar la selección de campaña
  const toggleView = (view) => {
    setViewingPreviousCampaigns(view === "previous");
    if (view === "create") {
      setSelectedCampaign(null); // Limpiar la selección al cambiar a crear campaña
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-4xl">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Campañas de Marketing
        </h2>

        {/* Botones para alternar entre Crear y Ver Campañas Anteriores */}
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 mr-2 rounded ${
              !viewingPreviousCampaigns
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => toggleView("create")}
          >
            Crear Nueva Campaña
          </button>
          <button
            className={`px-4 py-2 rounded ${
              viewingPreviousCampaigns
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => toggleView("previous")}
          >
            Ver Campañas Anteriores
          </button>
        </div>

        {/* Mostrar la cuota restante solo en la vista de crear campaña */}
        {!viewingPreviousCampaigns && (
          <div className="mb-4">
            <p className="text-lg">
              <strong>Mensajes restantes hoy:</strong> {remainingQuota}
            </p>
          </div>
        )}

        {/* Vista de Crear Nueva Campaña */}
        {!viewingPreviousCampaigns && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Crear Nueva Campaña</h3>
            <div className="mb-4">
              <label className="block mb-2">Nombre de la Campaña:</label>
              <input
                type="text"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                className="p-2 border border-gray-400 rounded w-full"
                placeholder="Ejemplo: Promoción de Verano"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Selecciona un Template:</label>
              <select
                value={templateId}
                onChange={(e) => setTemplateId(e.target.value)}
                className="p-2 border border-gray-400 rounded w-full"
              >
                <option value="">Seleccione un template aprobado</option>
                {templates.map((template) => (
                  <option
                    key={template.id}
                    value={template.status === "APPROVED" ? template.name : ""}
                    disabled={template.status !== "APPROVED"}
                  >
                    {template.name} ({template.status})
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">
                Números de Teléfono (separados por comas):
              </label>
              <textarea
                value={numbers}
                onChange={(e) => setNumbers(e.target.value)}
                className="p-2 border border-gray-400 rounded w-full"
                rows="3"
                placeholder="Ejemplo: 34657524749, 34987654321"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block mb-2">O importa un archivo CSV:</label>
              <input
                type="file"
                accept=".csv"
                onChange={(e) => setCsvFile(e.target.files[0])}
                className="p-2"
              />
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={onClose}
              >
                Cerrar
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleSendCampaign}
              >
                Enviar Campaña
              </button>
            </div>
          </div>
        )}

        {/* Vista de Campañas Anteriores */}
        {viewingPreviousCampaigns && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Campañas Anteriores</h3>
            {campaigns.length === 0 ? (
              <p>No hay campañas previas.</p>
            ) : (
              <ul className="space-y-2 max-h-96 overflow-y-auto">
                {campaigns.map((campaign) => (
                  <li
                    key={campaign.campaignId}
                    className="border p-3 rounded cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSelectCampaign(campaign)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <span className="font-bold">
                          {campaign.campaignName}
                        </span>
                        <span className="text-sm text-gray-500">
                          {" "}
                          - Template: {campaign.templateUsed}
                        </span>
                      </div>
                      <span className="text-blue-500">Ver Números</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {/* Detalles de la Campaña Seleccionada */}
        {selectedCampaign && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">
              Detalles de la Campaña: {selectedCampaign.campaignName}
            </h3>
            <p>
              <strong>Template Utilizado:</strong>{" "}
              {selectedCampaign.templateUsed}
            </p>
            <p>
              <strong>Números Enviados:</strong>
            </p>
            <ul className="list-disc list-inside">
              {selectedCampaign.numbersSent.map((number, index) => (
                <li key={index}>{number}</li>
              ))}
            </ul>
            <button
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
              onClick={handleCloseDetails}
            >
              Cerrar Detalles
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default MarketingCampaignModal;
